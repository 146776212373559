import './App.css';
import {Stack, Button, AppBar, Link, Box, Paper} from "@mui/material";
import {BsBarChartLine, BsBarChartFill} from 'react-icons/bs';
import {SiGraphql, SiSpring, SiSpringboot, SiJenkins, SiSonarqube, SiMaterialui} from 'react-icons/si';
import {CiMedal} from "react-icons/ci";
import {Parallax, ParallaxLayer} from '@react-spring/parallax'
import React, {useRef} from "react";
import styles from "./style.module.css";
import {BsCalendarWeek} from "react-icons/bs"
import {FaNodeJs, FaDocker} from 'react-icons/fa';
import {FiGitlab} from 'react-icons/fi';
import {GiCamel, GiClick} from 'react-icons/gi';
import {DiJira} from 'react-icons/di';
import {ImDatabase} from "react-icons/im";
import {IoLogoJavascript, IoLogoCss3} from "react-icons/io";
import {ImHtmlFive} from "react-icons/im";
import {FaReact} from "react-icons/fa";

function App() {
    const parallax = useRef(null);
    const scroll = (to) => {
        if (parallax.current) {
            parallax.current.scrollTo(to)
        }
    }
    const url = (name, wrap = false) =>
        `${wrap ? 'url(' : ''}https://awv3node-homepage.surge.sh/build/assets/${name}.svg${wrap ? ')' : ''}`
  return (
      <>
          <div className="App">
              <Box zIndex={30} class="blink_me">
                  <GiClick size={50} color="#023047" />
              </Box>
              <div style={{ width: '100%', height: '100%', background: '#253237' }}>
                  <Parallax ref={parallax} pages={3}>
                      <ParallaxLayer offset={1} speed={1} style={{ backgroundColor: '#805E73' }} />
                      <ParallaxLayer offset={2} speed={1} style={{ backgroundColor: '#87BCDE' }} />

                      <ParallaxLayer
                          offset={0}
                          speed={0}
                          factor={3}
                          style={{
                              backgroundColor: '#fff',
                              backgroundSize: 'cover',
                          }}
                      />

                      <ParallaxLayer offset={1.1} speed={0.3} style={{ pointerEvents: 'none' }}>
                          <Stack direction="row" alignItems="center" spacing={2}>
                              <Box style={{ marginLeft: '10%' }}>
                                <BsCalendarWeek size={100} color="#1e6091"/>
                              </Box>
                              <Box textAlign="left" fontSize="30px" color="#1e6091">
                                  Mon<br/>
                                  experience
                              </Box>
                          </Stack>
                      </ParallaxLayer>

                      <ParallaxLayer offset={2} speed={0.3} style={{ pointerEvents: 'none' }}>
                          <Stack direction="row" alignItems="center" spacing={2} ml="70%" mt={5}>
                              <Box style={{ marginLeft: '10%' }}>
                                  <BsBarChartFill size={100} color="#1e6091"/>
                              </Box>
                              <Box textAlign="left" fontSize="30px" color="#1e6091">
                                  Mes<br/>
                                  compétences
                              </Box>
                          </Stack>
                      </ParallaxLayer>

                      <ParallaxLayer
                          offset={2}
                          speed={0.4}
                          style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              pointerEvents: 'none',
                          }}>
                          <Paper elevation={2} style={{ width:'700px', height:'300px', marginLeft: '60%' }} borderRadius={2} sx={{ border: '1px solid #023047' }}>
                              <Stack flex={1} alignContent="center" p={2} bgcolor="#023047" justifyContent="center" direction="row">
                                  <Stack flex={1} direction="row" spacing={1} width="100%" height="100%">
                                      <div class="circle" style={{ backgroundColor: 'red' }}></div>
                                      <div class="circle" style={{ backgroundColor: 'orange' }}></div>
                                      <div class="circle" style={{ backgroundColor: 'green' }}></div>
                                  </Stack>
                                  <Box flex={20} color="#fff">FRONT END</Box>
                              </Stack>
                              <Stack flex={4} height="90%" justifyContent="center" spacing={3}>
                                  <Stack direction="row" width="100%" spacing={3} justifyContent="center">
                                      <IoLogoJavascript style={{ height: '40px', width: '40px' }} />
                                      <FaReact style={{ height: '40px', width: '40px' }} />
                                      <SiMaterialui style={{ height: '40px', width: '40px' }} />
                                      <ImHtmlFive style={{ height: '40px', width: '40px' }} />
                                      <IoLogoCss3 style={{ height: '40px', width: '40px' }} />
                                  </Stack>
                                  <Box sx={{ fontSize: '20px', color: 'gray' }}>Javascript, ReactJS, Material UI, HTML, CSS</Box>
                              </Stack>
                          </Paper>
                      </ParallaxLayer>

                      <ParallaxLayer
                          offset={2}
                          speed={-0.2}
                          style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              pointerEvents: 'none',
                          }}>
                          <Paper style={{ width:'700px', height:'300px', marginTop: '10%' }} borderRadius={2} sx={{ border: '1px solid #023047' }}>
                              <Stack flex={1} alignContent="center" p={2} bgcolor="#023047" justifyContent="center" direction="row">
                                  <Stack flex={1} direction="row" spacing={1} width="100%" height="100%">
                                      <div class="circle" style={{ backgroundColor: 'red' }}></div>
                                      <div class="circle" style={{ backgroundColor: 'orange' }}></div>
                                      <div class="circle" style={{ backgroundColor: 'green' }}></div>
                                  </Stack>
                                  <Box flex={20} color="#fff">INTEGRATION CONTINUE</Box>
                              </Stack>
                              <Stack flex={4} height="90%" justifyContent="center" spacing={3}>
                                  <Stack direction="row" width="100%" spacing={3} justifyContent="center">
                                      <FiGitlab style={{ height: '40px', width: '40px' }} />
                                      <SiJenkins style={{ height: '40px', width: '40px' }} />
                                      <FaDocker style={{ height: '40px', width: '40px' }} />
                                      <SiSonarqube style={{ height: '40px', width: '40px' }} />
                                      <DiJira style={{ height: '40px', width: '40px' }} />
                                  </Stack>
                                  <Box sx={{ fontSize: '20px', color: 'gray' }}>Git, Docker, Jenkins, Sonar, Jira</Box>
                              </Stack>
                          </Paper>
                      </ParallaxLayer>

                      <ParallaxLayer
                          offset={2}
                          speed={0.6}
                          style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              pointerEvents: 'none',
                          }}>
                          <Paper style={{ width:'700px', height:'300px', marginRight: '60%', marginTop: '-10%' }} borderRadius={2} sx={{ border: '1px solid #023047' }}>
                              <Stack flex={1} alignContent="center" p={2} bgcolor="#023047" justifyContent="center" direction="row">
                                  <Stack flex={1} direction="row" spacing={1} width="100%" height="100%">
                                      <div class="circle" style={{ backgroundColor: 'red' }}></div>
                                      <div class="circle" style={{ backgroundColor: 'orange' }}></div>
                                      <div class="circle" style={{ backgroundColor: 'green' }}></div>
                                  </Stack>
                                  <Box flex={20} color="#fff">BACK END</Box>
                              </Stack>
                              <Stack flex={4} height="90%" justifyContent="center" spacing={3}>
                                  <Stack direction="row" width="100%" spacing={3} justifyContent="center">
                                      <SiSpring style={{ height: '40px', width: '40px' }} />
                                      <SiSpringboot style={{ height: '40px', width: '40px' }} />
                                      <FaNodeJs style={{ height: '40px', width: '40px' }} />
                                      <GiCamel style={{ height: '40px', width: '40px' }} />
                                      <ImDatabase style={{ height: '40px', width: '40px' }} />
                                      <SiGraphql  style={{ height: '40px', width: '40px' }} />
                                  </Stack>
                                  <Box sx={{ fontSize: '20px', color: 'gray' }}>Java, Spring / Spring Boot, NodeJS, MySQL, Camel, GraphQL</Box>
                              </Stack>
                          </Paper>
                      </ParallaxLayer>

                      <ParallaxLayer offset={0} speed={0.3} style={{ pointerEvents: 'none' }}>
                          <div class="firstShape" />
                      </ParallaxLayer>

                      <ParallaxLayer offset={0} speed={0.3} style={{ pointerEvents: 'none' }}>
                          <div class="firstShape" />
                      </ParallaxLayer>

                      <ParallaxLayer offset={1} speed={0.3} style={{ pointerEvents: 'none' }}>
                          <div class="secondShape" />
                      </ParallaxLayer>

                      <ParallaxLayer offset={2} speed={0.3} style={{ pointerEvents: 'none' }}>
                          <div class="thirdShape" />
                      </ParallaxLayer>

                      {/*<ParallaxLayer offset={0} speed={0.3} style={{ pointerEvents: 'none' }}>*/}
                      {/*    <Box width="30px" ml={30} mt={40}>*/}
                      {/*        <img width="250px" height="250px" class="mask" src="./moi.jpg" alt="julienricome" />*/}
                      {/*    </Box>*/}
                      {/*</ParallaxLayer>*/}

                      <ParallaxLayer
                          offset={0}
                          speed={0.1}
                          onClick={() => parallax.current.scrollTo(1)}
                          style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                          }}>
                          <Stack textAlign="left" ml={20}>
                              <Stack>
                                  <Box color="#fff">Je m'appelle</Box>
                                  <Box ml="50px" color="#48cae4" fontSize="80px">Julien Ricome </Box>
                                  <Box color="#fff">et je suis</Box>
                                  <Box ml="20px" fontSize="30px" color="#48cae4" mt={2} fontWeight="bold">Ingénieur Full Stack.</Box>
                                  <Box mt={10} color="#fff">
                                      Je suis capable d'intervenir sur tous les aspects d'un projet. Curieux et autonome, j'apprends et je m'adapte vite.
                                  </Box>
                                  <Box mt={2} color="#fff">
                                      Grace à mes expériences passées, je possède un bon niveau d'anglais, tant à l'écrit qu'à l'oral.
                                  </Box>
                              </Stack>
                          </Stack>
                      </ParallaxLayer>

                      <ParallaxLayer
                          offset={1.1}
                          speed={0.1}
                          onClick={() => parallax.current.scrollTo(2)}
                          style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                          }}>
                              <Stack direction="row" spacing={10} px={10}>
                                  <Stack borderRadius={1} bgcolor="#023047" flex={1} p={5} spacing={5}>
                                      <Stack direction="row" justifyContent="space-between">
                                          <Box><img src="airfrance.png" alt="airfrance" width="100px"/></Box>
                                          <Box color="#fff">Aout 2011 - Mai 2014</Box>
                                      </Stack>
                                      <Stack textAlign="left" spacing={2} color="white">
                                          <Box>Durant mon apprentissage puis en mission chez Air France (avec Astek), j'ai mis en place un pilotage des processus ITIL via une application web d'aide à la gestion des processus.</Box>
                                          <Box>Bâtie from scratch, cette application se branche directement sur l'application de gestion des processus ITIL pour récupérer ses données, via des batchs développés avec Talend</Box>
                                          <Box>Le portail, baptisé Cockpit, permet de visualiser l'état des lieux de l'avancement des changements et des incidents, pour ainsi mieux anticiper les impacts sur le SI d'AirFrance</Box>
                                          <Box>En collaboration avec KLM, j'ai de plus assuré l'intérim du Process Manager du Processus Changement au sein de la production informatique d'Air France.</Box>
                                      </Stack>
                                  </Stack>
                                  <Stack borderRadius={1} bgcolor="#023047" flex={1} p={5} spacing={5}>
                                      <Stack direction="row" justifyContent="space-between">
                                          <Box><img src="orange.png" alt="orange" width="50px"/></Box>
                                          <Box color="#fff">Mai 2014 - Mars 2017</Box>
                                      </Stack>
                                      <Stack textAlign="left" spacing={2} color="white">
                                          <Box>En tant qu'expert technique, ma mission consistait à la mise en place de nouvelles librairies pour la supervision de différents équipements réseau (IP, GPRS...)</Box>
                                          <Box>Après la réception du cahier des charges rédigé par le client et après analyse du besoin, je me consacrais à la rédaction d'un chiffrage précis.</Box>
                                          <Box>Au sein de l'équipe, je participais à la réalisation des développements, mais aussi à la formation des nouveaux arrivants ainsi qu'au maintien en condition opérationnelle de plusieurs applications.</Box>
                                          <Box>En collaboration avec KLM, j'ai de plus assuré l'intérim du Process Manager du Processus Changement au sein de la production informatique d'Air France.</Box>
                                      </Stack>
                                  </Stack>
                                  <Stack borderRadius={1} bgcolor="#023047" flex={1} p={5} spacing={5}>
                                      <Stack direction="row" justifyContent="space-between">
                                          <Box><img src="lyra.png" alt="lyra" width="50px"/></Box>
                                          <Box color="#fff">Mars 2017 - Aujourd'hui</Box>
                                      </Stack>
                                      <Stack textAlign="left" spacing={2} color="white">
                                          <Box>Leader dans le domaine des solutions de paiement. Dans un contexte de progression, j'ai participé à la mise en place du nouveau système de facturation.</Box>
                                          <Box>Nous avons créé un nouveau portail, basé sur React et Material UI, conçu en collaboration avec les utilisateurs, au travers de différentes maquettes.</Box>
                                          <Box>Nous avons aussi refondu la totalité des traitements, pour mettre en place une architecture basée sur des micro services et une API développée avec GraphQL.</Box>
                                      </Stack>
                                  </Stack>
                              </Stack>
                      </ParallaxLayer>
                  </Parallax>
              </div>
          </div>
      </>

  );
}

export default App;
